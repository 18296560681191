import React from 'react';
import PropTypes from 'prop-types';

import getUrl from '@utils/getUrl';
import urls from '@constants/urls';
import useGetInsurance from '@utils/useGetInsurance';

import TransactionsList from '@components/containers/TransactionsList';

const InsuranceTransactions = ({ insuranceId }) => {
  const { loading, insurance } = useGetInsurance(insuranceId, getUrl(urls.HOME));
  if (!insurance) return null;

  const url = getUrl(urls.HOME.MY_INSURANCES.DETAILS, { insuranceId });
  const { transactions } = insurance;

  return <TransactionsList url={url} loading={loading} transactions={transactions} />;
};

InsuranceTransactions.propTypes = {
  insuranceId: PropTypes.string.isRequired,
};

export default InsuranceTransactions;
