import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import LoggedPage from '@components/containers/LoggedPage';
import FlexBox from '@components/containers/FlexBox';
import Transaction from '@components/containers/Transaction';
import Card from '@components/containers/Card';
import Headline from '@components/elements/Headline';
import Button from '@components/elements/Button';
import Table from '@components/elements/table/Table';
import Loader from '@components/elements/Loader';

const INITIAL_COUNT_SHOWN = 20;
const INCREMENT_STEP = 10;

const TransactionsList = ({ url, transactions, loading }) => {
  const [currentShown, setCurrentShown] = useState(INITIAL_COUNT_SHOWN);

  const increaseCurrentShown = useCallback(() => {
    setCurrentShown(currentShown + INCREMENT_STEP);
  }, [currentShown]);

  const transactionShown = Math.min(currentShown, transactions.length);
  const hasMore = currentShown < transactions.length;

  const monthsHash = transactions
    .slice()
    .reverse()
    .slice(0, transactionShown)
    .reduce((hash, transaction) => {
      const monthName = DateTime.fromISO(transaction.date).toFormat('MMMM yyyy');
      if (hash[monthName]) {
        hash[monthName].push(transaction);
        return hash;
      }
      return {
        ...hash,
        [monthName]: [transaction],
      };
    }, {});

  return (
    <LoggedPage
      title="Transaktioner"
      previousUrl={url}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {Object.keys(monthsHash).map((monthName) => (
            <Card key={monthName}>
              <FlexBox alignItems="center" marginBottom="regular">
                <Headline fontWeight="bold" fontSize="tiny" as="h2" textTransform="capitalize">{monthName}</Headline>
              </FlexBox>
              <FlexBox overflow="auto">
                <Table borderAfter>
                  <tbody>
                    {monthsHash[monthName].map((transaction) => (
                      <Transaction key={transaction._id} transaction={transaction} />
                    ))}
                  </tbody>
                </Table>
              </FlexBox>
            </Card>
          ))}
          {hasMore && (
            <FlexBox justifyContent="center" marginTop="regular">
              <Button onClick={increaseCurrentShown}>Visa mer</Button>
            </FlexBox>
          )}
        </>
      )}
    </LoggedPage>
  );
};

TransactionsList.propTypes = {
  url: PropTypes.string.isRequired,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      paymentDetails: PropTypes.shape({
        lender: PropTypes.string,
        accountNumber: PropTypes.string.isRequired,
        bankAccountType: PropTypes.string.isRequired,
        ocrNumber: PropTypes.number,
      }),
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TransactionsList;
